<template>
<div style="width:100%;">
  <el-container>
      <div class="list-select">
        <el-row class="list-select-row" style="text-align:left;font-weight:600">
          <el-col :span="2">
            <span>筛选条件</span>
          </el-col>
        </el-row>
        <el-row class="list-select-row">
          <FilterCondition v-model="defultAddress" @search-site="searchSite" :name="'关注品牌店编号'"></FilterCondition>
        </el-row>
      </div>
  </el-container>
  <el-container>
      <div class="list-select list-table">
        <el-row>
            <el-col :span="2"  style="text-align:left;font-weight:600">
              <span>关注品牌列表</span>
            </el-col>
            <el-col :span="2" :offset="20">
              <el-button type="primary" size="small" icon="el-icon-plus" @click="newVSDetail">新建</el-button>
            </el-col>
        </el-row>
        <el-row style="margin-top:20px;">
            <el-table 
            :data="tableData"
            style="width: 100%;font-size: 12px;"
            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
            :row-style="{height:'25px'}"
            :cell-style="{padding:'0px'}"
            >
            <el-table-column
                :label="$t('pipleine.ppindex')"
                align="center"
                width="80"
                show-overflow-tooltip>
                <template slot-scope="scope">
                    <span>{{((scope.$index+1) + (ops.page-1)*ops.size)}}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="code"
                label="关注品牌店编号"
                width="280"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="cnName"
                label="门店名称"
                width="180"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="mainBrand"
                label="品牌"
                width="180"
                show-overflow-tooltip>
            </el-table-column>
            <!-- <el-table-column
                prop="status"
                label="门店状态"
                width="100">
            </el-table-column>
              <el-table-column
                prop="openDate"
                label="开业日期"
                width="100">
            </el-table-column> -->
            <el-table-column
                prop="provinceName"
                label="省份"
                width="100"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="cityName"
                label="城市"
                width="100"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="districtName"
                label="区县"
                width="100"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                label="详细地址"
                show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-button icon="el-icon-location" @click="handleClick(scope.row)" type="text" size="small"></el-button>
                    {{scope.row.address}}
                </template>
            </el-table-column>
            </el-table>
        </el-row>
        <el-row style="text-align:right; margin-top:30px;">
            <el-pagination
            background
            layout="prev, pager, next"
            @current-change="turningPage"
            :page-size="ops.size"
            :current-page="ops.page"
            :total="tablePage">
            </el-pagination>
        </el-row>
      </div>
  </el-container>
  <ListVSDetail :vsVisible="showVSDetail" @update-vsvisible="upDateVSDetail"></ListVSDetail>
  </div>
</template>
<script>
import FilterCondition from "@/components/List/FilterCondition.vue";
import ListVSDetail from "@/components/List/ListVSDetail.vue";
import api from "@/api/index.js";
export default {
    components:{
        FilterCondition, ListVSDetail
    },
    data(){
        return {
        showVSDetail: false,
        defultAddress:{
            province: "",
            city: "",
            district: "",
            parameter: "",
            areaCode: ""
            },
            ops: {
            cusNo: sessionStorage.cusNo,
            provinceCode: "",
            cityCode: "",
            districtCode: "",
            parameter: "",
            size: 10,
            page: 1
            },
            tableData: [],
            tablePage: 0
        }
    },
    mounted() {
        this.searchCompetitor(this.ops);
    },
    methods: {
        newVSDetail(){
            this.showVSDetail = true;
        },
        upDateVSDetail(value){
            this.showVSDetail = value;
        },
        searchSite(val) {
            this.ops = {
                cusNo: sessionStorage.cusNo,
                provinceCode: val.province,
                cityCode: val.city,
                districtCode: val.district,
                parameter: val.parameter,
                size: 10,
                page: 1
            };
            this.searchCompetitor(this.ops);
        },
        //翻页  更新页码
        turningPage(page) {
            this.ops.page = page;
            this.searchCompetitor(this.ops);
        },
        searchCompetitor(ops) {
            var that = this;
            api.searchCompetitor(ops).then(res => {
                if(res.code == 200) {
                that.tableData = res.data.rows || [];
                that.tablePage = res.data.total || 0;
                }
            })
        },
        //快速定位到地图位置
        handleClick(val) {
            if(val.latitude != null && val.longitude) {
                sessionStorage.lat = val.latitude;
                sessionStorage.lng = val.longitude;
                this.$store.state.location = {
                    type: "vs",
                    data: val
                }
                this.$router.push("/home");
            }
        }
    }
}
</script>
<style>
.list-select{
    background: #fff;
    width: 100%;
    border-radius: 5px; 
    padding-bottom: 23px;
}
.list-table{
    margin-top:10px;
    padding: 10px;
}
.list-select-row{
    padding: 10px;
}
</style>